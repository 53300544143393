import React, { useEffect, useMemo, useState } from "react";
import { createClient } from "contentful";
import { navigate } from "@reach/router";
import Article from "../templates/article";

// This page is for content editors to preview an article before publishing it in contentful
export default ({ location }) => {
  const [article, setArticle] = useState(null);
  const articleId = useMemo(
    () => location.search.slice(1, location.search.length),
    []
  );
  const articleBlockList = article
    ? article.articleBlockList?.map(articleBlock => ({
        ...articleBlock.fields,
        contentBlockList1: articleBlock.fields.contentBlockList1.map(
          contentBlock => ({
            ...contentBlock.fields,
            content: {
              raw: JSON.stringify(contentBlock.fields?.content),
              assetReferences: []
            }
          })
        ),
        contentBlockList2: articleBlock.fields.contentBlockList2?.map(
          contentBlock => ({
            ...contentBlock.fields,
            content: {
              raw: JSON.stringify(contentBlock.fields?.content),
              assetReferences: []
            }
          })
        ),
        contentBlockList3: articleBlock.fields.contentBlockList3?.map(
          contentBlock => ({
            ...contentBlock.fields,
            content: {
              raw: JSON.stringify(contentBlock.fields?.content),
              assetReferences: []
            }
          })
        )
      }))
    : undefined;
  const authors = article
    ? article.authors?.map(apiAuthor => apiAuthor.fields)
    : [];
  const callToActionBanner =
    article && article.callToActionBanner
      ? {
          ...article.callToActionBanner.fields,
          image: article.callToActionBanner.fields.image.fields
        }
      : undefined;
  const category = article ? article.category.fields : {};
  const image =
    article && article.image
      ? {
          file: { url: article.image.fields.file.url },
          title: article.image.fields.title
        }
      : { file: { url: "" }, title: "" };
  const redirectBanner =
    article && article.redirectBanner
      ? {
          ...article.redirectBanner.fields
        }
      : undefined;
  const tags =
    article && article.tags ? article.tags.map(apiTag => apiTag.fields) : [];

  useEffect(() => {
    if (process.env.GATSBY_NODE_ENV === "development") {
      const client = createClient({
        space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
        accessToken: process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
        host: process.env.GATSBY_CONTENTFUL_PREVIEW_HOST
      });

      client
        .getEntry(articleId, { include: 4 })
        .then(entry => {
          setArticle(entry.fields);
        })
        .catch(err => console.log("An error occurred.", err));
    } else {
      navigate("/404");
    }
  }, []);

  return article ? (
    <Article
      pageContext={{
        article: {
          ...article,
          articleBlockList,
          authors,
          callToActionBanner,
          category,
          image,
          redirectBanner,
          tags
        },
        articles: [],
        // @ts-ignore
        content: {}
      }}
    />
  ) : (
    <span>Fetching article...</span>
  );
};
