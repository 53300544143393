import React from "react";
import classnames from "classnames";
import { IArticleBlock } from "./types";
import ArticleContentBlock from "./ArticleContentBlock";
import Divider from "../divider/Divider";
import FadeinDiv from "../fadeinDiv/FadeinDiv";
import "./article.css";

const ArticleBlock: React.FC<IArticleBlock> = ({
  backgroundColor,
  blockWidth,
  contentBlockList1,
  contentBlockList2,
  contentBlockList3,
  dividers,
  hideBottomPadding,
  hideTopSpacing,
  numColumns,
  textColor,
  title
}) => {
  return (
    <FadeinDiv
      className={classnames("article-block", {
        "article-block--wide": blockWidth === "large"
      })}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        paddingBottom: hideBottomPadding ? 0 : undefined,
        paddingTop: hideTopSpacing ? 0 : undefined
      }}
    >
      {(dividers === "both" || dividers === "top") && (
        <Divider className="divider-top" />
      )}
      {title && <p className="article-block-title">{title}</p>}
      <div
        className="article-content-block-wrapper"
        style={{
          gridTemplateColumns: `repeat(${numColumns}, 1fr)`
        }}
      >
        <div className="article-content-block-col">
          {contentBlockList1?.map((contentBlockList, index) => (
            <React.Fragment key={"article-block-" + index}>
              <ArticleContentBlock {...contentBlockList} />
            </React.Fragment>
          ))}
        </div>
        {contentBlockList2 && (
          <div className="article-content-block-col">
            {contentBlockList2.map((contentBlockList, index) => (
              <React.Fragment key={index}>
                <ArticleContentBlock {...contentBlockList} />
              </React.Fragment>
            ))}
          </div>
        )}
        {contentBlockList3 && (
          <div className="article-content-block-col">
            {contentBlockList3.map((contentBlockList, index) => (
              <React.Fragment key={index}>
                <ArticleContentBlock {...contentBlockList} />
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      {(dividers === "both" || dividers === "bottom") && (
        <Divider className="divider-bottom" />
      )}
    </FadeinDiv>
  );
};

export default ArticleBlock;
