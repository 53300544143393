import React, { useMemo } from "react";
import classNames from "classnames";
import { Tag } from "@metyis-ds/tag";
import { useScroll } from "@metyis-ds/hooks";
import { Link } from "../components/Link/LinkWithPrevUrl";
import { IArticle } from "../components/article/types";
import { IContent } from "../contexts/ContentContext";
const getFriendlyUrl = require("../helpers/helpers.ts").getFriendlyUrl;
import ArticleBlock from "../components/article/ArticleBlock";
import ArticleList from "../components/article/ArticleList";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import CTABanner from "../components/banner/CTABanner";
import FadeinDiv from "../components/fadeinDiv/FadeinDiv";
import Page from "../components/page/Page";
import RedirectBanner from "../components/banner/RedirectBanner";
import "./article.css";

export default ({
  pageContext
}: {
  pageContext: { article: IArticle; articles: IArticle[]; content: IContent };
}) => {
  const { article, articles, content } = pageContext;
  const {
    articleBlockList,
    authors,
    category,
    callToActionBanner,
    date,
    headerTextColor,
    image,
    imageLegend,
    introType,
    redirectBanner,
    summary,
    tags,
    title
  } = article;
  const scroll = useScroll();

  const dateFormat = new Date(date).toLocaleDateString("en", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });

  const moreArticles = useMemo(
    () =>
      articles
        ?.filter(
          article =>
            article.title !== title &&
            article.category.category === category.category &&
            article.tags?.[0]?.tag === tags?.[0]?.tag
        )
        ?.sort((articleA, articleB) =>
          articleA.createdAt < articleB.createdAt ? 1 : -1
        )
        ?.slice(0, 3),
    [articles]
  );

  const scrollProgress = useMemo(() => {
    if (typeof window !== "undefined") {
      return (
        (scroll?.scroll * 100) /
        // @ts-ignore
        (window?.document?.scrollingElement?.offsetHeight - window?.innerHeight)
      );
    } else return 0;
  }, [scroll?.scroll]);

  return (
    <div id="article">
      <Page
        content={content}
        seo={{ description: summary.summary, image: image.file.url, title }}
      >
        <BreadCrumbs
          dynamic
          crumbsArray={[
            {
              name: "Impact",
              redirectUrl: "/impact"
            },
            {
              name: category.category,
              redirectUrl: getFriendlyUrl("impact", category.category)
            },
            {
              name: title
            }
          ]}
        />
        <section className="article">
          <FadeinDiv
            className={classNames(
              "article-header",
              introType === "hero" && "article-header--hero"
            )}
            style={{
              backgroundImage:
                introType === "hero" && image
                  ? `url(${image.file.url}?fm=jpg&fl=progressive)`
                  : null,
              color: introType === "hero" ? headerTextColor : undefined
            }}
          >
            <span className="article-title">{title}</span>
            <div className="article-details">
              <span className="article-date">{dateFormat}</span>
              {authors && (
                <span className="article-author">
                  {authors.map((author, index) => (
                    <span key={author.id}>
                      {index === 0
                        ? "By "
                        : index === authors.length - 1
                        ? " and "
                        : ", "}
                      {author.shouldHideFromList ? (
                        author.name
                      ) : (
                        <Link
                          style={{ textDecoration: "underline" }}
                          to={getFriendlyUrl("profile", author.url)}
                        >
                          {author.name}
                        </Link>
                      )}
                    </span>
                  ))}
                </span>
              )}
              <div className="article-tags">
                <div className="article-tag">
                  <Tag className="article-tag">{category.category}</Tag>
                </div>
                {tags?.map(tag => (
                  <Tag className="article-tag" key={tag.tag}>
                    {tag.tag}
                  </Tag>
                ))}
              </div>
            </div>
          </FadeinDiv>
          {introType === "default" && (
            <FadeinDiv className="article-image-wrapper">
              <img
                alt={image.title}
                className="article-image"
                src={image.file.url}
              />
            </FadeinDiv>
          )}
          {imageLegend?.imageLegend && (
            <span className="article-image-legend">
              {imageLegend?.imageLegend}
            </span>
          )}
          <div className="article-body" style={{ paddingBottom: 64 }}>
            {articleBlockList.map((articleBlock, index) => (
              <React.Fragment key={index}>
                <ArticleBlock {...articleBlock} />
              </React.Fragment>
            ))}
          </div>
          {redirectBanner && <RedirectBanner {...redirectBanner} />}
          {!!moreArticles.length && (
            <ArticleList
              list={moreArticles}
              title="More impact"
              type="Thumbnail"
            />
          )}
          <RedirectBanner title="Read more insights" redirectUrl="/impact" />
          {callToActionBanner && <CTABanner {...callToActionBanner} />}
        </section>

        <div className="article-progress-wrapper">
          <div
            className="article-progress"
            style={{ width: scrollProgress + "%" }}
          />
        </div>
      </Page>
    </div>
  );
};
