import React, { useContext, useMemo } from "react";
import { useLocation } from "@reach/router";
import classnames from "classnames";
import HeaderContext from "../../contexts/HeaderContext";
import { Link } from "../Link/LinkWithPrevUrl";
import "./breadcrumbs.css";

interface IProps {
  crumbsArray: { name: string; redirectUrl?: string }[];
  dynamic?: boolean;
  overlap?: boolean;
}

const BreadCrumbs: React.FC<IProps> = ({ dynamic, crumbsArray, overlap }) => {
  const { headerColor, blendMode } = useContext(HeaderContext);
  const location = useLocation();
  const customCrumbsArray = useMemo(() => {
    if (dynamic) {
      const prevUrl: string | undefined = location?.state?.prevUrl;
      const pages = [
        {
          name: "About",
          redirectUrl: "/about"
        },
        {
          name: "Careers",
          redirectUrl: "/careers"
        }
      ];

      const page = pages.find(p => prevUrl?.includes(p.redirectUrl));
      return [
        page || {
          name: "Impact",
          redirectUrl: "/impact"
        },
        ...crumbsArray.slice(1)
      ];
    } else {
      return crumbsArray;
    }
  }, [location, crumbsArray, dynamic, location]);

  return (
    <div
      className={classnames("breadcrumbs", {
        overlap,
        "blend-mode": blendMode
      })}
      style={{ color: overlap && headerColor }}
    >
      {customCrumbsArray.map((c, i) => (
        <div
          style={
            i === crumbsArray.length - 1
              ? {
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }
              : {}
          }
          key={c.name}
        >
          <Link to={c.redirectUrl ? c.redirectUrl : "#"}>
            <span
              className={classnames("breadcrumb-item", {
                "last-item": i === crumbsArray.length - 1
              })}
              style={{ color: overlap && headerColor }}
            >
              {c.name}
            </span>
          </Link>
          {i < crumbsArray.length - 1 && <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>}
        </div>
      ))}
    </div>
  );
};

export default BreadCrumbs;
