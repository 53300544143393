import React from "react";
import classNames from "classnames";
import { richText } from "../../templates/helpers";
import { IArticleContentBlock } from "./types";
import FadeinDiv from "../fadeinDiv/FadeinDiv";
import "./article.css";

const ArticleContentBlock: React.FC<IArticleContentBlock> = ({
  alignment,
  size,
  content
}) => (
  <FadeinDiv
    className={classNames(
      "article-content-block rich-text",
      alignment && `align-${alignment}`,
      size && `size-${size}`
    )}
  >
    {richText(content)}
  </FadeinDiv>
);

export default ArticleContentBlock;
