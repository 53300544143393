import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { SecondaryButton } from "@metyis-ds/button";
import { Link } from "../Link/LinkWithPrevUrl";
import { ICTABanner } from "./types";
import FadeinDiv from "../fadeinDiv/FadeinDiv";
import LazyLoadImage from "../image/LazyLoadImage";
import ArrowRight from "../../assets/arrow-right.svg";
import "./ctaBanner.css";

const CTABanner: React.FC<ICTABanner> = ({
  backgroundColor,
  buttonLabel,
  image,
  video,
  redirectUrl,
  text,
  iconCta,
  textColor,
  titleOrange,
  title,
  leftImage,
  smaller,
  half
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const isButtonVariant = !!buttonLabel;
  const hasVideo = !!video;

  const handleMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  const CtaContentEl = (
    <div className={classNames("cta-content", { smaller })}>
      {iconCta ? (
        <FadeinDiv className="cta-icon-wrapper">
          <LazyLoadImage
            alt={iconCta?.title}
            className="cta-icon"
            src={iconCta?.file.url}
          />
        </FadeinDiv>
      ) : null}
      <span className="cta-title">
        {titleOrange && (
          <p className="cta-title-orange" style={{ color: "#ED5B41" }}>
            {titleOrange}{" "}
          </p>
        )}
        {title}
      </span>
      <span className="cta-text">{text?.text}</span>

      {isButtonVariant ? (
        <SecondaryButton>
          {redirectUrl.startsWith("/") ? (
            <Link to={redirectUrl}>{buttonLabel}</Link>
          ) : (
            <a href={redirectUrl} target="_blank">
              {buttonLabel}
            </a>
          )}
        </SecondaryButton>
      ) : (
        <ArrowRight
          className={classNames("cta-arrow", { animate: isHovering })}
          fill={isHovering ? textColor : backgroundColor}
          stroke={isHovering ? backgroundColor : textColor}
        />
      )}
    </div>
  );

  return (
    <div
      className={classNames("cta-banner", {
        "cta-banner--button": isButtonVariant,
        "cta-banner--left-image": leftImage,
        "cta-banner--half": half
      })}
    >
      <FadeinDiv
        className="cta-content-wrapper"
        style={{
          backgroundColor:
            isHovering && !isButtonVariant ? textColor : backgroundColor,
          color: isHovering && !isButtonVariant ? backgroundColor : textColor,
          cursor: isHovering && !isButtonVariant ? "pointer" : "default"
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isButtonVariant ? (
          CtaContentEl
        ) : (
          <Link to={redirectUrl}>{CtaContentEl}</Link>
        )}
      </FadeinDiv>
      {hasVideo && (
        <FadeinDiv className="cta-video-wrapper">
          <video
            autoPlay
            playsInline
            loop
            muted
            className="cta-video"
            src={video.file.url}
          />
        </FadeinDiv>
      )}
      {!hasVideo && image && (
        <FadeinDiv className="cta-img-wrapper">
          <LazyLoadImage
            alt={image.title}
            className="cta-img"
            src={image.file.url}
          />
        </FadeinDiv>
      )}
    </div>
  );
};

export default CTABanner;
